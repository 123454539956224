import {
  IUserProfile,
  IEvent,
  ICheckoutPrep,
  ICheckoutSession,
  IDirectoryEntry,
  IEnrollment,
  ICertificate,
  IBlog,
  IResource,
  Wrapper,
} from "@/interfaces";
import { MainState, AppNotification } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const mutations = {
  setToken(state: MainState, payload: string) {
    state.token = payload;
  },
  setLoggedIn(state: MainState, payload: boolean) {
    state.isLoggedIn = payload;
  },
  setLogInError(state: MainState, payload: boolean) {
    state.logInError = payload;
  },
  setUserProfile(state: MainState, payload: IUserProfile) {
    state.userProfile = payload;
  },
  setCertificate(state: MainState, payload: ICertificate | null) {
    state.certificate = payload;
  },
  setDirectoryEntry(state: MainState, payload: IDirectoryEntry) {
    state.directoryEntry = payload;
  },
  setDirectoryEntries(
    state: MainState,
    payload: { entries: IDirectoryEntry[]; append: boolean },
  ) {
    if (payload.append) {
      state.directoryEntries.push(...payload.entries);
    } else {
      state.directoryEntries = payload.entries;
    }
  },
  setEnrolledEvents(state: MainState, payload: IEvent[]) {
    state.enrolledEvents = payload;
  },
  setEnrollments(state: MainState, payload: IEnrollment[]) {
    state.enrollments = payload;
  },
  setUpcomingEvents(state: MainState, payload: IEvent[]) {
    state.upcomingEvents = payload;
  },
  setCheckoutEvent(state: MainState, payload: IEvent) {
    state.checkoutEvent = payload;
  },
  setCheckoutPrep(state: MainState, payload: ICheckoutPrep) {
    state.checkoutPrep = payload;
  },
  setCheckoutSession(state: MainState, payload: ICheckoutSession) {
    state.checkoutSession = payload;
    if (state.checkoutSession?.status === "complete" && state.userProfile) {
      state.userProfile.requires_course = false;
    }
  },
  setDashboardMiniDrawer(state: MainState, payload: boolean) {
    state.dashboardMiniDrawer = payload;
  },
  setDashboardShowDrawer(state: MainState, payload: boolean) {
    state.dashboardShowDrawer = payload;
  },
  addNotification(state: MainState, payload: AppNotification) {
    state.notifications.push(payload);
  },
  removeNotification(state: MainState, payload: AppNotification) {
    state.notifications = state.notifications.filter(
      (notification) => notification !== payload,
    );
  },
  setBlogs(state: MainState, payload: Wrapper<IBlog>) {
    state.blogs = payload.items;
    state.blogsTotal = payload.meta.total_count;
  },
  setBlogQuery(state: MainState, payload: string | null) {
    state.blogQuery = payload;
  },
  setBlogPage(state: MainState, payload: number) {
    state.blogPage = payload;
  },
  setResources(state: MainState, payload: Wrapper<IResource>) {
    state.resources = payload.items;
    state.resourcesTotal = payload.meta.total_count;
  },
  setRecertEnrolled(state: MainState, payload: boolean) {
    state.recertEnrolled = payload;
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { commit } = getStoreAccessors<MainState | any, State>("");

export const commitSetDashboardMiniDrawer = commit(mutations.setDashboardMiniDrawer);
export const commitSetDashboardShowDrawer = commit(mutations.setDashboardShowDrawer);
export const commitSetLoggedIn = commit(mutations.setLoggedIn);
export const commitSetLogInError = commit(mutations.setLogInError);
export const commitSetToken = commit(mutations.setToken);
export const commitSetUserProfile = commit(mutations.setUserProfile);
export const commitSetDirectoryEntry = commit(mutations.setDirectoryEntry);
export const commitSetDirectoryEntries = commit(mutations.setDirectoryEntries);
export const commitSetEnrolledEvents = commit(mutations.setEnrolledEvents);
export const commitSetEnrollments = commit(mutations.setEnrollments);
export const commitSetUpcomingEvents = commit(mutations.setUpcomingEvents);
export const commitSetCheckoutEvent = commit(mutations.setCheckoutEvent);
export const commitSetCheckoutPrep = commit(mutations.setCheckoutPrep);
export const commitSetCheckoutSession = commit(mutations.setCheckoutSession);
export const commitAddNotification = commit(mutations.addNotification);
export const commitRemoveNotification = commit(mutations.removeNotification);
export const commitSetCertificate = commit(mutations.setCertificate);
export const commitSetBlogs = commit(mutations.setBlogs);
export const commitSetBlogQuery = commit(mutations.setBlogQuery);
export const commitSetBlogPage = commit(mutations.setBlogPage);
export const commitSetResources = commit(mutations.setResources);
export const commitSetRecertEnrolled = commit(mutations.setRecertEnrolled);
