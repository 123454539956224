
  import { Component, Vue } from "vue-property-decorator";
  import NotificationsManager from "@/components/NotificationsManager.vue";
  import { readIsLoggedIn } from "@/store/main/getters";
  import { dispatchCheckLoggedIn } from "@/store/main/actions";

  @Component({
    components: {
      NotificationsManager,
    },
  })
  export default class App extends Vue {
    get loggedIn() {
      return readIsLoggedIn(this.$store);
    }

    public async created() {
      await dispatchCheckLoggedIn(this.$store);
    }
  }
