export const getLocalToken = () => localStorage.getItem("token");

export const saveLocalToken = (token: string) => localStorage.setItem("token", token);

export const removeLocalToken = () => localStorage.removeItem("token");

export const debounce = (func, wait) => {
  let timeout;

  return async function executedFunction(...args) {
    const later = async () => {
      clearTimeout(timeout);
      await func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const formatMoney = (amount: number | null) => {
  return amount ? `$${(amount / 100).toFixed(2)}` : "$0.00";
};
