import { MainState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const getters = {
  hasAdminAccess: (state: MainState) => {
    return (
      state.userProfile && state.userProfile.is_superuser && state.userProfile.is_active
    );
  },
  loginError: (state: MainState) => state.logInError,
  dashboardShowDrawer: (state: MainState) => state.dashboardShowDrawer,
  dashboardMiniDrawer: (state: MainState) => state.dashboardMiniDrawer,
  userProfile: (state: MainState) => state.userProfile,
  directoryEntry: (state: MainState) => state.directoryEntry,
  directoryEntries: (state: MainState) => state.directoryEntries,
  enrolledEvents: (state: MainState) => state.enrolledEvents,
  enrollments: (state: MainState) => state.enrollments,
  upcomingEvents: (state: MainState) => state.upcomingEvents,
  checkoutEvent: (state: MainState) => state.checkoutEvent,
  checkoutPrep: (state: MainState) => state.checkoutPrep,
  checkoutSession: (state: MainState) => state.checkoutSession,
  token: (state: MainState) => state.token,
  isLoggedIn: (state: MainState) => state.isLoggedIn,
  firstNotification: (state: MainState) =>
    state.notifications.length > 0 && state.notifications[0],
  certificate: (state: MainState) => state.certificate,
  blogs: (state: MainState) => state.blogs,
  blogsTotal: (state: MainState) => state.blogsTotal,
  blogQuery: (state: MainState) => state.blogQuery,
  blogPage: (state: MainState) => state.blogPage,
  resources: (state: MainState) => state.resources,
  resourcesTotal: (state: MainState) => state.resourcesTotal,
  recertEnrolled: (state: MainState) => state.recertEnrolled,
};

const { read } = getStoreAccessors<MainState, State>("");

export const readDashboardMiniDrawer = read(getters.dashboardMiniDrawer);
export const readDashboardShowDrawer = read(getters.dashboardShowDrawer);
export const readHasAdminAccess = read(getters.hasAdminAccess);
export const readIsLoggedIn = read(getters.isLoggedIn);
export const readLoginError = read(getters.loginError);
export const readToken = read(getters.token);
export const readUserProfile = read(getters.userProfile);
export const readDirectoryEntry = read(getters.directoryEntry);
export const readDirectoryEntries = read(getters.directoryEntries);
export const readEnrolledEvents = read(getters.enrolledEvents);
export const readEnrollments = read(getters.enrollments);
export const readUpcomingEvents = read(getters.upcomingEvents);
export const readCheckoutEvent = read(getters.checkoutEvent);
export const readCheckoutPrep = read(getters.checkoutPrep);
export const readCheckoutSession = read(getters.checkoutSession);
export const readFirstNotification = read(getters.firstNotification);
export const readCertificate = read(getters.certificate);
export const readBlogs = read(getters.blogs);
export const readBlogsTotal = read(getters.blogsTotal);
export const readBlogQuery = read(getters.blogQuery);
export const readBlogPage = read(getters.blogPage);
export const readResources = read(getters.resources);
export const readResourcesTotal = read(getters.resourcesTotal);
export const readRecertEnrolled = read(getters.recertEnrolled);
