const env = process.env.VUE_APP_ENV;

let envApiUrl = "";
let envCMSApiUrl = "";
let envStripePublicKey = "";

if (env === "production") {
  envApiUrl = `https://${process.env.VUE_APP_DOMAIN_PROD}`;
  envCMSApiUrl = "/cms/api/v2";
  envStripePublicKey = process.env.VUE_APP_STRIPE_PUBLIC_KEY_LIVE;
} else if (env === "staging") {
  envApiUrl = `https://${process.env.VUE_APP_DOMAIN_STAG}`;
  envCMSApiUrl = "/cms/api/v2";
  envStripePublicKey = process.env.VUE_APP_STRIPE_PUBLIC_KEY_TEST;
} else {
  envApiUrl = `http://${process.env.VUE_APP_DOMAIN_DEV}`;
  envCMSApiUrl = "http://localhost/cms/api/v2";
  envStripePublicKey = process.env.VUE_APP_STRIPE_PUBLIC_KEY_TEST;
}

export const apiUrl = envApiUrl;
export const cmsApiUrl = envCMSApiUrl;
export const appName = process.env.VUE_APP_NAME;
export const stripePublicKey = envStripePublicKey;
export const THINKIFIC_POST_TEST_GRACE_PERIOD_DAYS = 31;

export const featureFlagSast = process.env.VUE_APP_FEATURE_FLAG_SAST === "true";
