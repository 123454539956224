import { AdminState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const getters = {
  adminUsers: (state: AdminState) => state.users,
  adminOneUser: (state: AdminState) => (userId: number) => {
    const filteredUsers = state.usersSearchResults.users.filter(
      (user) => user.id === userId,
    );
    if (filteredUsers.length > 0) {
      return { ...filteredUsers[0] };
    }
  },
  adminUsersSearchResults: (state: AdminState) => state.usersSearchResults,
  adminEvents: (state: AdminState) => state.events,
  usersSearchOptions: (state: AdminState) => state.usersSearchOptions,
  usersSearchQuery: (state: AdminState) => state.usersSearchQuery,
  eventsSearchOptions: (state: AdminState) => state.eventsSearchOptions,
  eventsSearchQuery: (state: AdminState) => state.eventsSearchQuery,
};

const { read } = getStoreAccessors<AdminState, State>("");

export const readAdminOneUser = read(getters.adminOneUser);
export const readAdminUsers = read(getters.adminUsers);
export const readAdminEvents = read(getters.adminEvents);
export const readAdminUsersSearchResults = read(getters.adminUsersSearchResults);
export const readUsersSearchOptions = read(getters.usersSearchOptions);
export const readUsersSearchQuery = read(getters.usersSearchQuery);
export const readEventsSearchOptions = read(getters.eventsSearchOptions);
export const readEventsSearchQuery = read(getters.eventsSearchQuery);
