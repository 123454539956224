import { api } from "@/api";
import { ActionContext } from "vuex";
import {
  IUserProfileCreate,
  IUserProfileUpdate,
  IUserProfileCardUpdate,
} from "@/interfaces";
import { State } from "../state";
import { AdminState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import {
  commitSetUsers,
  commitSetUser,
  commitSetEvents,
  commitSetUsersSearchResults,
} from "./mutations";
import { dispatchCheckApiError } from "../main/actions";
import { commitAddNotification, commitRemoveNotification } from "../main/mutations";

type MainContext = ActionContext<AdminState, State>;

export const actions = {
  async actionGetUsers(context: MainContext) {
    try {
      const response = await api.getUsers(context.rootState.main.token);
      if (response) {
        commitSetUsers(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateUser(
    context: MainContext,
    payload: { id: number; user: IUserProfileUpdate },
  ) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.updateUser(context.rootState.main.token, payload.id, payload.user),
          await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetUser(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "User successfully updated",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionAdminUpdateUserProfileCard(
    context: MainContext,
    payload: { id: number; user: IUserProfileCardUpdate },
  ) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.updateUserProfileCard(
            context.rootState.main.token,
            payload.id,
            payload.user,
          ),
          await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetUser(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "User profile card successfully updated",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreateUser(context: MainContext, payload: IUserProfileCreate) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.createUser(context.rootState.main.token, payload),
          await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetUser(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "User successfully created",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetEvents(
    context: MainContext,
    payload: { options; searchQuery: string | null },
  ) {
    try {
      const options = payload.options;
      const params = {
        limit: options.itemsPerPage,
        skip: options.itemsPerPage * (options.page - 1),
      };
      if (options.sortBy.length > 0) {
        params["sort_by"] = options.sortBy[0];
      }
      if (options.sortDesc.length > 0) {
        params["desc"] = !!options.sortDesc[0];
      }
      if (payload.searchQuery) {
        params["query"] = payload.searchQuery;
      }
      const response = await api.getEvents(context.rootState.main.token, params);
      if (response) {
        commitSetEvents(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionSearchUsers(
    context: MainContext,
    payload: { options; searchQuery: string | null },
  ) {
    try {
      const options = payload.options;
      const params = {
        limit: options.itemsPerPage,
        skip: options.itemsPerPage * (options.page - 1),
      };
      if (options.sortBy.length > 0) {
        params["sort_by"] = options.sortBy[0];
      }
      if (options.sortDesc.length > 0) {
        params["desc"] = !!options.sortDesc[0];
      }
      if (payload.searchQuery) {
        params["query"] = payload.searchQuery;
      }
      const response = await api.searchUsers(context.rootState.main.token, params);
      if (response) {
        commitSetUsersSearchResults(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetAnswersReportMonthly(
    context: MainContext,
    payload: { year: number; month: number },
  ) {
    try {
      const params = {
        year: payload.year,
        month: payload.month,
      };
      const response = await api.getAnswersReportMonthly(
        context.rootState.main.token,
        params,
      );
      if (response.data) {
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetAnswersReportForEvent(
    context: MainContext,
    payload: { eventId: number },
  ) {
    try {
      const response = await api.getAnswersReportForEvent(
        context.rootState.main.token,
        payload.eventId,
      );
      if (response.data) {
        return response;
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
};

const { dispatch } = getStoreAccessors<AdminState, State>("");

export const dispatchCreateUser = dispatch(actions.actionCreateUser);
export const dispatchGetUsers = dispatch(actions.actionGetUsers);
export const dispatchUpdateUser = dispatch(actions.actionUpdateUser);
export const dispatchAdminUpdateUserProfileCard = dispatch(
  actions.actionAdminUpdateUserProfileCard,
);
export const dispatchSearchUsers = dispatch(actions.actionSearchUsers);
export const dispatchGetEvents = dispatch(actions.actionGetEvents);
export const dispatchGetAnswersReportMonthly = dispatch(
  actions.actionGetAnswersReportMonthly,
);
export const dispatchGetAnswersReportByEvent = dispatch(
  actions.actionGetAnswersReportForEvent,
);
