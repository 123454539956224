import axios from "axios";
import { apiUrl } from "@/env";
import {
  IUserProfile,
  IUserProfileUpdate,
  IUserProfileCreate,
  IEvent,
  INameChangeRequestCreate,
  IUserProfileCardUpdate,
  IUserSignUp,
  ICheckoutSessionCreate,
  ICheckoutSession,
  ICheckoutFillOrder,
  IBaseCheckoutPrep,
  IDirectoryEntry,
  IMaterialsDownload,
  IEnrollment,
  IThinkificSSOUrl,
  ICanPurchaseMaterialsDownload,
  IProduct,
  IProductUpdate,
  IEventsWrapper,
  IEnrollmentAttendee,
  IEventAdmin,
  IEventUpdate,
  IUsersWrapper,
  IChangeRequestsWrapper,
  ICertificatesWrapper,
  ICertificateUpdate,
  ICheckoutSessionWithEvent,
  IHostSeminar,
  ICertificate,
  IThinkificProduct,
  IQuestionDetail,
  IAnswer,
} from "./interfaces";

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const api = {
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append("username", username);
    params.append("password", password);

    return axios.post(`${apiUrl}/api/v1/login/access-token`, params, {
      withCredentials: true,
    });
  },
  async logout() {
    return axios.get(`${apiUrl}/api/v1/logout`);
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`, authHeaders(token));
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(
      `${apiUrl}/api/v1/users/me`,
      data,
      authHeaders(token),
    );
  },
  async updateMyProfileCard(token: string, data: IUserProfileCardUpdate) {
    return axios.put<IUserProfile>(
      `${apiUrl}/api/v1/users/me`,
      data,
      authHeaders(token),
    );
  },
  async updateUserProfileCard(
    token: string,
    userId: number,
    data: IUserProfileCardUpdate,
  ) {
    return axios.put<IUserProfile>(
      `${apiUrl}/api/v1/users/${userId}`,
      data,
      authHeaders(token),
    );
  },
  async getMyDirectoryEntry(token: string) {
    return axios.get<IDirectoryEntry>(
      `${apiUrl}/api/v1/directory_entries/me/`,
      authHeaders(token),
    );
  },
  async searchDirectory(payload: {
    query: string | null;
    country: string | null;
    province: string | null;
    town: string | null;
    limit: number;
    skip: number;
  }) {
    return axios.get<IDirectoryEntry[]>(`${apiUrl}/api/v1/directory_entries/search/`, {
      params: payload,
    });
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/`, authHeaders(token));
  },
  async searchUsers(token: string, params) {
    return axios.get<IUsersWrapper>(`${apiUrl}/api/v1/users/search/`, {
      params: params,
      headers: authHeaders(token).headers,
    });
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/users/${userId}`, data, authHeaders(token));
  },
  async updateMyAvatar(token: string, file: File) {
    const formData = new FormData();
    formData.append("file", file, file.name);
    return axios.post(
      `${apiUrl}/api/v1/users/avatar/upload/`,
      formData,
      authHeaders(token),
    );
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/users/`, data, authHeaders(token));
  },
  async registerUser(data: IUserSignUp) {
    return axios.post(`${apiUrl}/api/v1/register`, data, {
      withCredentials: true,
    });
  },
  async confirm(token: string) {
    return axios.post(`${apiUrl}/api/v1/confirm`, { token });
  },
  async resendConfirm(email: string) {
    return axios.post(`${apiUrl}/api/v1/resend_confirm`, { email });
  },
  async getCertificates(token: string) {
    return axios.get<ICertificate[]>(
      `${apiUrl}/api/v1/certificates/me/`,
      authHeaders(token),
    );
  },
  async getEnrolledEvents(token: string) {
    return axios.get<IEvent[]>(
      `${apiUrl}/api/v1/events/enrolled/current`,
      authHeaders(token),
    );
  },
  async getEnrollments(token: string) {
    return axios.get<IEnrollment[]>(
      `${apiUrl}/api/v1/enrollments/me/`,
      authHeaders(token),
    );
  },
  async removeEnrollment(token: string, enrollmentId: number) {
    return axios.delete<IEnrollment>(
      `${apiUrl}/api/v1/enrollments/${enrollmentId}`,
      authHeaders(token),
    );
  },
  async getUpcomingEvents() {
    return axios.get<IEvent[]>(`${apiUrl}/api/v1/events/upcoming/`);
  },
  async getEvent(token: string, eventId: number) {
    return axios.get<IEvent>(`${apiUrl}/api/v1/events/${eventId}`, authHeaders(token));
  },
  async getEventAdmin(token: string, eventId: number) {
    return axios.get<IEventAdmin>(
      `${apiUrl}/api/v1/events/${eventId}/admin/`,
      authHeaders(token),
    );
  },
  async updateEvent(token: string, eventId: number, data: IEventUpdate) {
    return axios.put<IEventAdmin>(
      `${apiUrl}/api/v1/events/${eventId}`,
      data,
      authHeaders(token),
    );
  },
  async getMaterialsDownload(token: string, eventId: number | null) {
    return axios.get<IMaterialsDownload>(
      `${apiUrl}/api/v1/material_downloads/available/`,
      {
        params: eventId ? { event_id: eventId } : {},
        headers: authHeaders(token).headers,
      },
    );
  },
  async consumeMaterialsDownload(token: string, eventId: number) {
    return axios.get<IMaterialsDownload>(
      `${apiUrl}/api/v1/material_downloads/download/`,
      {
        params: { event_id: eventId },
        headers: authHeaders(token).headers,
      },
    );
  },
  async checkCanPurchaseMaterialsDownload(token: string) {
    return axios.get<ICanPurchaseMaterialsDownload>(
      `${apiUrl}/api/v1/material_downloads/can_purchase/`,
      authHeaders(token),
    );
  },
  async registerThinkific(token: string, payload: { event_id: number; code: string }) {
    return axios.post<IThinkificSSOUrl>(
      `${apiUrl}/api/v1/thinkific/register`,
      payload,
      authHeaders(token),
    );
  },
  async getThinkificSSOUrl(
    token: string,
    toCertificates: boolean,
    toRecert: boolean,
    return_to: string | null = null,
  ) {
    const params = { to_certificates: toCertificates, to_recert: toRecert };
    if (return_to !== null) {
      params["return_to"] = return_to;
    }
    return axios.get<IThinkificSSOUrl>(`${apiUrl}/api/v1/thinkific/sso_login`, {
      params: params,
      headers: authHeaders(token).headers,
    });
  },
  async prepareCheckout(token: string, eventId: number) {
    return axios.get<IBaseCheckoutPrep>(`${apiUrl}/api/v1/checkout/prepare`, {
      params: { event_id: eventId },
      headers: authHeaders(token).headers,
    });
  },
  async createCheckoutSession(token: string, data: ICheckoutSessionCreate) {
    return axios.post<ICheckoutSession>(
      `${apiUrl}/api/v1/checkout/create`,
      data,
      authHeaders(token),
    );
  },
  async createStripeCheckoutSession(
    token: string,
    checkoutSessionId: number,
    data: ICheckoutFillOrder,
  ) {
    return axios.post<ICheckoutSession>(
      `${apiUrl}/api/v1/checkout/${checkoutSessionId}/create_stripe_checkout_session`,
      data,
      authHeaders(token),
    );
  },
  async createMaterialsDownloadCheckoutSession(token: string) {
    return axios.post<ICheckoutSession>(
      `${apiUrl}/api/v1/checkout/material_download/create_checkout_session`,
      {},
      authHeaders(token),
    );
  },
  async getCheckoutSession(token: string, checkoutSessionId: number) {
    return axios.get<ICheckoutSession>(
      `${apiUrl}/api/v1/checkout/session/${checkoutSessionId}`,
      authHeaders(token),
    );
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/v1/reset-password/`, {
      new_password: password,
      token,
    });
  },
  async requestNameChange(token: string, data: INameChangeRequestCreate) {
    return axios.post(`${apiUrl}/api/v1/change_requests/`, data, authHeaders(token));
  },
  async getProducts(token: string) {
    return axios.get<IProduct[]>(`${apiUrl}/api/v1/products/`, authHeaders(token));
  },
  async updateProduct(token: string, productId: number, payload: IProductUpdate) {
    return axios.put<IProduct>(
      `${apiUrl}/api/v1/products/${productId}`,
      payload,
      authHeaders(token),
    );
  },
  async getMaterialsDownloadProduct(token: string) {
    return axios.get<IProduct>(
      `${apiUrl}/api/v1/products/type/material_download`,
      authHeaders(token),
    );
  },
  async getEarlyRegistrationProduct() {
    return axios.get<IProduct>(`${apiUrl}/api/v1/products/type/early_registration`);
  },
  async getRegularRegistrationProduct() {
    return axios.get<IProduct>(`${apiUrl}/api/v1/products/type/regular_registration`);
  },
  async getEvents(token: string, params) {
    return axios.get<IEventsWrapper>(`${apiUrl}/api/v1/events/search/`, {
      params: params,
      headers: authHeaders(token).headers,
    });
  },
  async getEventAttendees(token: string, eventId: number) {
    return axios.get<IEnrollmentAttendee[]>(
      `${apiUrl}/api/v1/enrollments/events/${eventId}/attendees/`,
      authHeaders(token),
    );
  },
  async giveDownload(token: string, enrollmentId: number) {
    return axios.patch<IEnrollmentAttendee>(
      `${apiUrl}/api/v1/enrollments/${enrollmentId}/give_download/`,
      null,
      authHeaders(token),
    );
  },
  async searchChangeRequests(token: string, params) {
    return axios.get<IChangeRequestsWrapper>(
      `${apiUrl}/api/v1/change_requests/search/`,
      {
        params: params,
        headers: authHeaders(token).headers,
      },
    );
  },
  async approveChangeRequest(token: string, changeRequestId: number) {
    return axios.get<IChangeRequestsWrapper>(
      `${apiUrl}/api/v1/change_requests/${changeRequestId}/approve`,
      authHeaders(token),
    );
  },
  async denyChangeRequest(token: string, changeRequestId: number) {
    return axios.get<IChangeRequestsWrapper>(
      `${apiUrl}/api/v1/change_requests/${changeRequestId}/deny`,
      authHeaders(token),
    );
  },
  async searchCertificates(token: string, params) {
    return axios.get<ICertificatesWrapper>(`${apiUrl}/api/v1/certificates/search/`, {
      params: params,
      headers: authHeaders(token).headers,
    });
  },
  async updateCertificate(
    token: string,
    certificateId: number,
    data: ICertificateUpdate,
  ) {
    return axios.put(
      `${apiUrl}/api/v1/certificates/${certificateId}`,
      data,
      authHeaders(token),
    );
  },
  async getPurchases(token: string) {
    return axios.get<ICheckoutSessionWithEvent[]>(
      `${apiUrl}/api/v1/checkout/purchases`,
      authHeaders(token),
    );
  },
  async sendHostSeminarRequest(token: string, data: IHostSeminar) {
    return axios.post(
      `${apiUrl}/api/v1/messages/host_seminar`,
      data,
      authHeaders(token),
    );
  },
  async getRecertCourse(token: string) {
    return axios.get<IThinkificProduct>(
      `${apiUrl}/api/v1/thinkific/recert-course/info`,
      authHeaders(token),
    );
  },
  async createRecertCourseCheckoutSession(token: string, data: IAnswer[]) {
    return axios.post<ICheckoutSession>(
      `${apiUrl}/api/v1/checkout/recert/create_checkout_session`,
      data,
      authHeaders(token),
    );
  },
  async getRecertEnrolled(token: string) {
    return axios.get<boolean>(
      `${apiUrl}/api/v1/thinkific/recert-course/enrolled`,
      authHeaders(token),
    );
  },
  async getRegistrationQuestions(token: string) {
    return axios.get<IQuestionDetail[]>(
      `${apiUrl}/api/v1/registration_questions/`,
      authHeaders(token),
    );
  },
  async getAnswersReportMonthly(token: string, params) {
    return axios.get(`${apiUrl}/api/v1/registration_questions/report/monthly`, {
      params: params,
      headers: authHeaders(token).headers,
      responseType: "blob",
    });
  },
  async getAnswersReportForEvent(token: string, eventId: number) {
    return axios.get(
      `${apiUrl}/api/v1/registration_questions/report/event/${eventId}`,
      {
        headers: authHeaders(token).headers,
        responseType: "blob",
      },
    );
  },
};
