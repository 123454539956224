import { IEventsWrapper, IUserProfile, IUsersWrapper } from "@/interfaces";
import { AdminState } from "./state";
import { getStoreAccessors } from "typesafe-vuex";
import { State } from "../state";

export const mutations = {
  setUsers(state: AdminState, payload: IUserProfile[]) {
    state.users = payload;
  },
  setUser(state: AdminState, payload: IUserProfile) {
    const users = state.users.filter((user: IUserProfile) => user.id !== payload.id);
    users.push(payload);
    state.users = users;
  },
  setUsersSearchResults(state: AdminState, payload: IUsersWrapper) {
    state.usersSearchResults = payload;
  },
  setUsersSearchOptions(state: AdminState, payload) {
    state.usersSearchOptions = payload;
  },
  setUsersSearchQuery(state: AdminState, payload: string | null) {
    state.usersSearchQuery = payload;
  },
  setEvents(state: AdminState, payload: IEventsWrapper) {
    state.events = payload;
  },
  setEventsSearchOptions(state: AdminState, payload) {
    state.eventsSearchOptions = payload;
  },
  setEventsSearchQuery(state: AdminState, payload: string | null) {
    state.eventsSearchQuery = payload;
  },
};

const { commit } = getStoreAccessors<AdminState, State>("");

export const commitSetUser = commit(mutations.setUser);
export const commitSetUsers = commit(mutations.setUsers);
export const commitSetEvents = commit(mutations.setEvents);
export const commitSetUsersSearchResults = commit(mutations.setUsersSearchResults);
export const commitSetUsersSearchOptions = commit(mutations.setUsersSearchOptions);
export const commitSetUsersSearchQuery = commit(mutations.setUsersSearchQuery);
export const commitSetEventsSearchOptions = commit(mutations.setEventsSearchOptions);
export const commitSetEventsSearchQuery = commit(mutations.setEventsSearchQuery);
