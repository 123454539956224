import Vue from "vue";
import Router from "vue-router";

import RouterComponent from "./components/RouterComponent.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return {
        selector: to.hash,
      };
    }
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: "/",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "start" */ "./views/main/Start.vue"),
      children: [
        {
          path: "public",
          alias: "",
          component: () =>
            import(/* webpackChunkName: "public" */ "./views/public/Public.vue"),
          children: [
            {
              path: "home",
              alias: "",
              component: () =>
                import(/* webpackChunkName: "home" */ "./views/public/Home.vue"),
            },
            {
              path: "mdtp",
              component: () =>
                import(/* webpackChunkName: "mdtp" */ "./views/public/MDTP.vue"),
            },
            {
              path: "evidence",
              component: () =>
                import(
                  /* webpackChunkName: "evidence" */ "./views/public/Evidence.vue"
                ),
            },
            {
              path: "about",
              component: () =>
                import(/* webpackChunkName: "about" */ "./views/public/About.vue"),
            },
            {
              path: "michael-crary",
              component: () =>
                import(
                  /* webpackChunkName: "michael-crary" */ "./views/public/MichaelCrary.vue"
                ),
            },
            {
              path: "giselle-carnaby",
              component: () =>
                import(
                  /* webpackChunkName: "giselle-carnaby" */ "./views/public/GiselleCarnaby.vue"
                ),
            },
            {
              path: "login",
              name: "login",
              component: () =>
                import(/* webpackChunkName: "login" */ "./views/Login.vue"),
            },
            {
              path: "recover-password",
              component: () =>
                import(
                  /* webpackChunkName: "recover-password" */ "./views/PasswordRecovery.vue"
                ),
            },
            {
              path: "reset-password",
              component: () =>
                import(
                  /* webpackChunkName: "reset-password" */ "./views/ResetPassword.vue"
                ),
            },
            {
              path: "sign-up",
              component: () =>
                import(/* webpackChunkName: "sign-up" */ "./views/public/SignUp.vue"),
            },
            {
              path: "confirm",
              component: () =>
                import(/* webpackChunkName: "confirm" */ "./views/public/Confirm.vue"),
            },
            {
              path: "directory",
              component: () =>
                import(
                  /* webpackChunkName: "directory" */ "./views/public/Directory.vue"
                ),
            },
            {
              path: "events",
              component: () =>
                import(/* webpackChunkName: "events" */ "./views/public/Events.vue"),
            },
            {
              path: "event/:id",
              name: "event",
              component: () =>
                import(/* webpackChunkName: "event" */ "./views/public/Event.vue"),
            },
            {
              path: "checkout/:id",
              name: "checkout",
              component: () =>
                import(
                  /* webpackChunkName: "checkout" */ "./views/public/Checkout.vue"
                ),
            },
            {
              path: "checkout-success/:id",
              component: () =>
                import(
                  /* webpackChunkName: "checkout-success" */ "./views/public/CheckoutSuccess.vue"
                ),
            },
            {
              path: "materials/checkout",
              component: () =>
                import(
                  /* webpackChunkName: "materials-checkout" */ "./views/public/CheckoutMaterialsDownload.vue"
                ),
            },
            {
              path: "recertify/checkout",
              component: () =>
                import(
                  /* webpackChunkName: "recertify-checkout" */ "./views/public/CheckoutRecertification.vue"
                ),
            },
            {
              path: "terms",
              component: () =>
                import(/* webpackChunkName: "terms" */ "./views/public/Terms.vue"),
            },
            {
              path: "privacy-policy",
              component: () =>
                import(
                  /* webpackChunkName: "privacy-policy" */ "./views/public/PrivacyPolicy.vue"
                ),
            },
            {
              path: "refund-policy",
              component: () =>
                import(
                  /* webpackChunkName: "refund-policy" */ "./views/public/RefundPolicy.vue"
                ),
            },
            {
              path: "host-seminar",
              component: () =>
                import(
                  /* webpackChunkName: "host-seminar" */ "./views/public/HostSeminar.vue"
                ),
            },
          ],
        },
        {
          path: "main",
          component: () =>
            import(/* webpackChunkName: "main" */ "./views/main/Main.vue"),
          children: [
            {
              path: "dashboard",
              component: () =>
                import(
                  /* webpackChunkName: "main-dashboard" */ "./views/main/Dashboard.vue"
                ),
            },
            {
              path: "my-courses",
              component: () =>
                import(
                  /* webpackChunkName: "my-courses" */ "./views/main/MyCourses.vue"
                ),
            },
            {
              path: "purchase-history",
              component: () =>
                import(
                  /* webpackChunkName: "purchase-history" */ "./views/main/PurchaseHistory.vue"
                ),
            },
            {
              path: "blog",
              component: () =>
                import(
                  /* webpackChunkName: "blog-index" */ "./views/main/BlogIndex.vue"
                ),
            },
            {
              path: "blog/:id",
              component: () =>
                import(/* webpackChunkName: "blog-page" */ "./views/main/BlogPage.vue"),
            },
            {
              path: "resources",
              component: () =>
                import(
                  /* webpackChunkName: "resources" */ "./views/main/Resources.vue"
                ),
            },
            {
              path: "profile",
              component: RouterComponent,
              redirect: "profile/view",
              children: [
                {
                  path: "view",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile" */ "./views/main/profile/UserProfile.vue"
                    ),
                },
                {
                  path: "edit",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile-edit" */ "./views/main/profile/UserProfileEdit.vue"
                    ),
                },
                {
                  path: "password",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-profile-password" */ "./views/main/profile/UserProfileEditPassword.vue"
                    ),
                },
              ],
            },
            {
              path: "admin",
              component: () =>
                import(
                  /* webpackChunkName: "main-admin" */ "./views/main/admin/Admin.vue"
                ),
              redirect: "admin/users/all",
              children: [
                {
                  path: "users",
                  redirect: "users/all",
                },
                {
                  path: "users/all",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-users" */ "./views/main/admin/AdminUsers.vue"
                    ),
                },
                {
                  path: "users/edit/:id",
                  name: "main-admin-users-edit",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-users-edit" */ "./views/main/admin/EditUser.vue"
                    ),
                },
                {
                  path: "users/create",
                  name: "main-admin-users-create",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-users-create" */ "./views/main/admin/CreateUser.vue"
                    ),
                },
                {
                  path: "events/all",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-events" */ "./views/main/admin/AdminEvents.vue"
                    ),
                },
                {
                  path: "events/detail/:id",
                  name: "main-admin-events-detail",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-events-detail" */ "./views/main/admin/AdminEventDetail.vue"
                    ),
                },
                {
                  path: "change_requests",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-change-requests" */ "./views/main/admin/AdminChangeRequests.vue"
                    ),
                },
                {
                  path: "certificates",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-certificates" */ "./views/main/admin/AdminCertificates.vue"
                    ),
                },
                {
                  path: "products",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-products" */ "./views/main/admin/AdminProducts.vue"
                    ),
                },
                {
                  path: "reports",
                  component: () =>
                    import(
                      /* webpackChunkName: "main-admin-reports" */ "./views/main/admin/AdminReports.vue"
                    ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "/*",
      redirect: "/",
    },
  ],
});
