/* eslint-disable @typescript-eslint/no-use-before-define */
import { api } from "@/api";
import { cmsApi } from "@/cmsApi";
import {
  ICheckoutFillOrder,
  INameChangeRequestCreate,
  IUserProfileCardUpdate,
  IUserSignUp,
} from "@/interfaces";
import router from "@/router";
import { getLocalToken, removeLocalToken, saveLocalToken } from "@/utils";
import axios from "axios";
import { getStoreAccessors } from "typesafe-vuex";
import { ActionContext } from "vuex";
import { State } from "../state";
import {
  commitAddNotification,
  commitRemoveNotification,
  commitSetLoggedIn,
  commitSetLogInError,
  commitSetToken,
  commitSetUserProfile,
  commitSetEnrolledEvents,
  commitSetUpcomingEvents,
  commitSetCheckoutPrep,
  commitSetCheckoutEvent,
  commitSetCheckoutSession,
  commitSetDirectoryEntry,
  commitSetEnrollments,
  commitSetDirectoryEntries,
  commitSetCertificate,
  commitSetBlogs,
  commitSetResources,
  commitSetRecertEnrolled,
} from "./mutations";
import { AppNotification, MainState } from "./state";

type MainContext = ActionContext<MainState, State>;

export const actions = {
  async actionLogIn(
    context: MainContext,
    payload: { username: string; password: string },
  ) {
    try {
      const response = await api.logInGetToken(payload.username, payload.password);
      const token = response.data.access_token;
      if (token) {
        saveLocalToken(token);
        commitSetToken(context, token);
        commitSetLoggedIn(context, true);
        commitSetLogInError(context, false);
        await dispatchGetUserProfile(context);
        await dispatchRouteLoggedIn(context);
        commitAddNotification(context, { content: "Logged in", color: "success" });
      } else {
        await dispatchLogOut(context);
      }
    } catch (err) {
      commitSetLogInError(context, true);
      await dispatchLogOut(context);
    }
  },
  async actionGetUserProfile(context: MainContext) {
    try {
      const response = await api.getMe(context.state.token);
      if (response.data) {
        commitSetUserProfile(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateUserProfile(context: MainContext, payload) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.updateMe(context.state.token, payload),
          await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetUserProfile(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "Profile successfully updated",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateUserProfileCard(
    context: MainContext,
    payload: IUserProfileCardUpdate,
  ) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = (
        await Promise.all([
          api.updateMyProfileCard(context.state.token, payload),
          await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
        ])
      )[0];
      commitSetUserProfile(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "Profile Card successfully updated",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUpdateUserAvatar(context: MainContext, payload: { file: File }) {
    try {
      const loadingNotification = { content: "saving", showProgress: true };
      commitAddNotification(context, loadingNotification);
      const response = await api.updateMyAvatar(context.state.token, payload.file);

      commitSetUserProfile(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "Avatar updated",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionUserSignUp(context: MainContext, payload: IUserSignUp) {
    try {
      const response = await api.registerUser(payload);
      const token = response.data.access_token;
      if (token) {
        saveLocalToken(token);
        commitSetToken(context, token);
        commitSetLoggedIn(context, true);
        commitSetLogInError(context, false);
        await dispatchGetUserProfile(context);
        await dispatchRouteSignedUp(context);
        commitAddNotification(context, { content: "Logged in", color: "success" });
      } else {
        await dispatchLogOut(context);
      }
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response?.data?.detail?.includes("already exists")
      ) {
        commitAddNotification(context, {
          content: "A user with this email already exists",
          color: "error",
        });
      }
    }
  },
  async actionConfirm(context: MainContext, payload) {
    try {
      const response = await api.confirm(payload.token);
      commitSetUserProfile(context, response.data);
      commitAddNotification(context, {
        content: "Email Confirmed",
        color: "success",
      });
    } catch (error) {
      if (
        axios.isAxiosError(error) &&
        error.response?.data?.detail?.includes("Invalid token")
      ) {
        commitAddNotification(context, {
          content: "Invalid token, try the Resend Email button",
          color: "error",
        });
      }
    }
  },
  async actionResendConfirm(context: MainContext, payload) {
    try {
      await api.resendConfirm(payload.email);
      commitAddNotification(context, {
        content: "Email Sent",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetCertificate(context: MainContext) {
    try {
      const response = await api.getCertificates(context.state.token);
      if (response.data) {
        commitSetCertificate(
          context,
          response.data.length > 0 ? response.data[0] : null,
        );
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetMyDirectoryEntry(context: MainContext) {
    try {
      const response = await api.getMyDirectoryEntry(context.state.token);
      if (response.data) {
        commitSetDirectoryEntry(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionSearchDirectory(
    context: MainContext,
    payload: {
      query: string | null;
      country: string | null;
      province: string | null;
      town: string | null;
      limit: number;
      skip: number;
    },
  ) {
    try {
      const response = await api.searchDirectory(payload);
      if (response.data) {
        commitSetDirectoryEntries(context, {
          entries: response.data,
          append: payload.skip > 0,
        });
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetEnrolledEvents(context: MainContext) {
    try {
      const response = await api.getEnrolledEvents(context.state.token);
      if (response.data) {
        commitSetEnrolledEvents(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetEnrollments(context: MainContext) {
    try {
      const response = await api.getEnrollments(context.state.token);
      if (response.data) {
        commitSetEnrollments(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetRecertEnrolled(context: MainContext) {
    try {
      const response = await api.getRecertEnrolled(context.state.token);
      if (response) {
        commitSetRecertEnrolled(context, true);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      commitSetRecertEnrolled(context, false);
    }
  },
  async actionGetUpcomingEvents(context: MainContext) {
    try {
      const response = await api.getUpcomingEvents();
      if (response.data) {
        commitSetUpcomingEvents(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetCheckoutEvent(context: MainContext, payload: { eventId: number }) {
    try {
      const response = await api.getEvent(context.state.token, payload.eventId);
      if (response.data) {
        commitSetCheckoutEvent(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionPrepareCheckout(context: MainContext, payload: { eventId: number }) {
    try {
      const response = await api.prepareCheckout(context.state.token, payload.eventId);
      if (response.data) {
        commitSetCheckoutPrep(context, { ...response.data, event_id: payload.eventId });
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCreateCheckoutSession(
    context: MainContext,
    payload: { eventId: number; ticketTypeId: string; discountCode: string },
  ) {
    try {
      const response = await api.createCheckoutSession(context.state.token, {
        event_id: payload.eventId,
        ticket_type_id: payload.ticketTypeId,
        discount_code: payload.discountCode,
      });
      if (response.data) {
        commitSetCheckoutSession(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
      if (axios.isAxiosError(error)) {
        const errorDetail = error.response?.data?.detail;
        if (errorDetail?.includes("already registered")) {
          commitAddNotification(context, {
            content: "You are already registered for this event",
            color: "error",
          });
        } else if (errorDetail?.includes("Invalid discount code")) {
          commitAddNotification(context, {
            content: "Invalid discount code",
            color: "error",
          });
        } else if (errorDetail?.includes("Event sold out")) {
          commitAddNotification(context, {
            content: "Event sold out",
            color: "error",
          });
        } else {
          commitAddNotification(context, {
            content: "Something went wrong, please try again",
            color: "error",
          });
        }
      }
    }
  },
  async actionCreateStripeCheckoutSession(
    context: MainContext,
    payload: { checkoutSessionId: number; checkoutFillOrder: ICheckoutFillOrder },
  ) {
    try {
      const response = await api.createStripeCheckoutSession(
        context.state.token,
        payload.checkoutSessionId,
        payload.checkoutFillOrder,
      );
      if (response.data) {
        commitSetCheckoutSession(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetCheckoutSession(
    context: MainContext,
    payload: { checkoutSessionId: number },
  ) {
    try {
      const response = await api.getCheckoutSession(
        context.state.token,
        payload.checkoutSessionId,
      );
      if (response.data) {
        commitSetCheckoutSession(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionCheckLoggedIn(context: MainContext) {
    if (!context.state.isLoggedIn) {
      let token = context.state.token;
      if (!token) {
        const localToken = getLocalToken();
        if (localToken) {
          commitSetToken(context, localToken);
          token = localToken;
        }
      }
      if (token) {
        try {
          const response = await api.getMe(token);
          commitSetLoggedIn(context, true);
          commitSetUserProfile(context, response.data);
        } catch (error) {
          await dispatchRemoveLogIn(context);
        }
      } else {
        await dispatchRemoveLogIn(context);
      }
    }
  },
  async actionRemoveLogIn(context: MainContext) {
    removeLocalToken();
    commitSetToken(context, "");
    commitSetLoggedIn(context, false);
  },
  async actionLogOut(context: MainContext) {
    await api.logout();
    await dispatchRemoveLogIn(context);
    await dispatchRouteLogOut(context);
  },
  async actionUserLogOut(context: MainContext) {
    await dispatchLogOut(context);
    commitAddNotification(context, { content: "Logged out", color: "success" });
  },
  actionRouteLogOut() {
    if (router.currentRoute.path !== "/login") {
      router.push("/login");
    }
  },
  async actionCheckApiError(context: MainContext, payload: unknown) {
    if (axios.isAxiosError(payload)) {
      if (payload.response?.status === 401) {
        await dispatchLogOut(context);
      }
    }
  },
  async actionRouteLoggedIn(context: MainContext) {
    if ("next" in router.currentRoute.query && router.currentRoute.query["next"]) {
      const next = router.currentRoute.query["next"] as string;
      if (next.startsWith("https://degluted.fdi2.com")) {
        const token = context.state.token;
        try {
          const resp = await api.getThinkificSSOUrl(token, false, false, next);
          window.location.href = resp.data.url;
        } catch (error) {
          dispatchCheckApiError(context, error);
        }
      } else {
        router.push(next);
      }
    }
    if (router.currentRoute.path === "/login" || router.currentRoute.path === "/") {
      router.push("/main");
    }
  },
  actionRouteSignedUp() {
    if (
      router.currentRoute.path === "/sign-up" ||
      router.currentRoute.path === "/public/sign-up"
    ) {
      router.push("/confirm");
    }
  },
  async removeNotification(
    context: MainContext,
    payload: { notification: AppNotification; timeout: number },
  ) {
    return new Promise((resolve) => {
      setTimeout(() => {
        commitRemoveNotification(context, payload.notification);
        resolve(true);
      }, payload.timeout);
    });
  },
  async passwordRecovery(context: MainContext, payload: { username: string }) {
    const loadingNotification = {
      content: "Sending password recovery email",
      showProgress: true,
    };
    try {
      commitAddNotification(context, loadingNotification);
      await Promise.all([
        api.passwordRecovery(payload.username),
        await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
      ]);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "Password recovery email sent",
        color: "success",
      });
      await dispatchLogOut(context);
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, { color: "error", content: "Incorrect username" });
    }
  },
  async resetPassword(
    context: MainContext,
    payload: { password: string; token: string },
  ) {
    const loadingNotification = { content: "Resetting password", showProgress: true };
    try {
      commitAddNotification(context, loadingNotification);
      await Promise.all([
        api.resetPassword(payload.password, payload.token),
        await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
      ]);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "Password successfully reset",
        color: "success",
      });
      await dispatchLogOut(context);
    } catch (error) {
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        color: "error",
        content: "Error resetting password",
      });
    }
  },
  async requestNameChange(context: MainContext, payload: INameChangeRequestCreate) {
    try {
      const loadingNotification = { content: "sending", showProgress: true };
      commitAddNotification(context, loadingNotification);
      await Promise.all([
        api.requestNameChange(context.state.token, payload),
        await new Promise<void>((resolve) => setTimeout(() => resolve(), 500)),
      ]);
      // commitSetChangeRequest(context, response.data);
      commitRemoveNotification(context, loadingNotification);
      commitAddNotification(context, {
        content: "Name Change Request Sent",
        color: "success",
      });
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async addNotification(context: MainContext, payload: AppNotification) {
    commitAddNotification(context, payload);
  },

  // CMS
  async actionSearchBlogs(
    context: MainContext,
    payload: { query: string | null; page: number },
  ) {
    try {
      const response = await cmsApi.getBlogs(payload.query, payload.page);
      if (response.data) {
        commitSetBlogs(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionGetBlog(context: MainContext, payload: number) {
    try {
      return (await cmsApi.getBlog(payload)).data;
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
  async actionSearchResources(
    context: MainContext,
    payload: { query: string | null; page: number },
  ) {
    try {
      const response = await cmsApi.getResources(payload.query, payload.page);
      if (response.data) {
        commitSetResources(context, response.data);
      }
    } catch (error) {
      await dispatchCheckApiError(context, error);
    }
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { dispatch } = getStoreAccessors<MainState | any, State>("");

export const dispatchCheckApiError = dispatch(actions.actionCheckApiError);
export const dispatchCheckLoggedIn = dispatch(actions.actionCheckLoggedIn);
export const dispatchGetUserProfile = dispatch(actions.actionGetUserProfile);
export const dispatchUserSignUp = dispatch(actions.actionUserSignUp);
export const dispatchConfirm = dispatch(actions.actionConfirm);
export const dispatchResendConfirm = dispatch(actions.actionResendConfirm);
export const dispatchGetDirectoryEntry = dispatch(actions.actionGetMyDirectoryEntry);
export const dispatchSearchDirectory = dispatch(actions.actionSearchDirectory);
export const dispatchGetEnrolledEvents = dispatch(actions.actionGetEnrolledEvents);
export const dispatchGetEnrollments = dispatch(actions.actionGetEnrollments);
export const dispatchGetUpcomingEvents = dispatch(actions.actionGetUpcomingEvents);
export const dispatchGetCheckoutEvent = dispatch(actions.actionGetCheckoutEvent);
export const dispatchPrepareCheckout = dispatch(actions.actionPrepareCheckout);
export const dispatchCreateCheckoutSession = dispatch(
  actions.actionCreateCheckoutSession,
);
export const dispatchCreateStripeCheckoutSession = dispatch(
  actions.actionCreateStripeCheckoutSession,
);
export const dispatchGetCheckoutSession = dispatch(actions.actionGetCheckoutSession);
export const dispatchLogIn = dispatch(actions.actionLogIn);
export const dispatchLogOut = dispatch(actions.actionLogOut);
export const dispatchUserLogOut = dispatch(actions.actionUserLogOut);
export const dispatchRemoveLogIn = dispatch(actions.actionRemoveLogIn);
export const dispatchRouteLoggedIn = dispatch(actions.actionRouteLoggedIn);
export const dispatchRouteSignedUp = dispatch(actions.actionRouteSignedUp);
export const dispatchRouteLogOut = dispatch(actions.actionRouteLogOut);
export const dispatchUpdateUserProfile = dispatch(actions.actionUpdateUserProfile);
export const dispatchUpdateUserAvatar = dispatch(actions.actionUpdateUserAvatar);
export const dispatchUpdateUserProfileCard = dispatch(
  actions.actionUpdateUserProfileCard,
);
export const dispatchRemoveNotification = dispatch(actions.removeNotification);
export const dispatchAddNotification = dispatch(actions.addNotification);
export const dispatchPasswordRecovery = dispatch(actions.passwordRecovery);
export const dispatchResetPassword = dispatch(actions.resetPassword);
export const dispatchRequestNameChange = dispatch(actions.requestNameChange);
export const dispatchGetCertificate = dispatch(actions.actionGetCertificate);
export const dispatchGetRecertEnrolled = dispatch(actions.actionGetRecertEnrolled);

export const dispatchSearchBlogs = dispatch(actions.actionSearchBlogs);
export const dispatchGetBlog = dispatch(actions.actionGetBlog);

export const dispatchSearchResources = dispatch(actions.actionSearchResources);

/* eslint-enable @typescript-eslint/no-use-before-define */
