import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { MainState } from "./state";

const defaultState: MainState = {
  isLoggedIn: null,
  token: "",
  logInError: false,
  userProfile: null,
  directoryEntry: null,
  directoryEntries: [],
  enrolledEvents: [],
  enrollments: [],
  upcomingEvents: [],
  checkoutEvent: null,
  checkoutPrep: null,
  checkoutSession: null,
  dashboardMiniDrawer: false,
  dashboardShowDrawer: true,
  notifications: [],
  certificate: null,
  blogs: [],
  blogsTotal: 0,
  blogQuery: null,
  blogPage: 1,
  resources: [],
  resourcesTotal: 0,
  recertEnrolled: false,
};

export const mainModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
