import axios from "axios";
import {
  Wrapper,
  IEvidence,
  IAssociatedEvidence,
  IPolicyDocument,
  IBlog,
  IBlogDetails,
  IResource,
} from "./interfaces";
import { cmsApiUrl } from "@/env";

export const cmsApi = {
  async getEvidence() {
    return axios.get<Wrapper<IEvidence>>(`${cmsApiUrl}/pages/`, {
      params: {
        type: "evidence.Evidence",
        fields: ["_", "id", "evidence_title", "description", "link"].join(","),
        limit: 100,
      },
    });
  },
  async getAssociatedEvidence() {
    return axios.get<Wrapper<IAssociatedEvidence>>(`${cmsApiUrl}/pages/`, {
      params: {
        type: "evidence.AssociatedEvidence",
        fields: ["_", "id", "evidence_title"].join(","),
        limit: 100,
      },
    });
  },
  async getTermsOfService() {
    return axios.get<Wrapper<IPolicyDocument>>(`${cmsApiUrl}/pages/`, {
      params: {
        type: "policy.PolicyDocument",
        fields: ["_", "body"].join(","),
        slug: "terms-and-conditions",
      },
    });
  },
  async getPrivacyPolicy() {
    return axios.get<Wrapper<IPolicyDocument>>(`${cmsApiUrl}/pages/`, {
      params: {
        type: "policy.PolicyDocument",
        fields: ["_", "body"].join(","),
        slug: "privacy-policy",
      },
    });
  },
  async getRefundPolicy() {
    return axios.get<Wrapper<IPolicyDocument>>(`${cmsApiUrl}/pages/`, {
      params: {
        type: "policy.PolicyDocument",
        fields: ["_", "body"].join(","),
        slug: "refund-policy",
      },
    });
  },
  async getBlogs(query: string | null, page: number) {
    const params = {
      type: "blog.BlogPage",
      fields: ["_", "id", "title", "date", "intro", "hero_image"].join(","),
      limit: 24,
      offset: (page - 1) * 24,
      search_operator: "and",
    };

    if (query) {
      params["search"] = query;
    } else {
      params["order"] = "-date";
    }

    return axios.get<Wrapper<IBlog>>(`${cmsApiUrl}/pages/`, {
      params,
      withCredentials: true,
    });
  },
  async getBlog(id: number) {
    return axios.get<IBlogDetails>(`${cmsApiUrl}/pages/${id}/`, {
      params: {
        fields: ["_", "id", "title", "date", "intro", "hero_image", "body"].join(","),
      },
      withCredentials: true,
    });
  },
  async getResources(query: string | null, page: number) {
    const params = {
      type: "fdi_resource.FDIResourcePage",
      fields: ["_", "id", "title", "date", "description", "resource_file"].join(","),
      limit: 24,
      offset: (page - 1) * 24,
      search_operator: "and",
    };

    if (query) {
      params["search"] = query;
    } else {
      params["order"] = "-date";
    }

    return axios.get<Wrapper<IResource>>(`${cmsApiUrl}/pages/`, {
      params,
      withCredentials: true,
    });
  },
};
