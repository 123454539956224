import { mutations } from "./mutations";
import { getters } from "./getters";
import { actions } from "./actions";
import { AdminState } from "./state";

const defaultState: AdminState = {
  users: [],
  usersSearchResults: {
    pagination: { total: 0 },
    users: [],
  },
  events: {
    pagination: { total: 0 },
    events: [],
  },
  usersSearchOptions: null,
  usersSearchQuery: null,
  eventsSearchOptions: null,
  eventsSearchQuery: null,
};

export const adminModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
